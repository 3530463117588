






































import { Component, Vue } from 'vue-property-decorator'
import Tab from '@/shared/classes/tabs/tab'
import { Routes } from '@/shared/router'
import Tabs from '../components/tabs/Tabs.vue'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import http from '@/shared/http'
import ChangePasswordDialog from '@/components/profile/ChangePasswordDialog.vue'

@Component({
  components: { Tabs }
})
export default class ProfileInnerLayout extends Vue {
  tabs: Tab[] = [
    new Tab()
      .setName(Routes.profileCalculationLogs)
      .setTitle('Calculation Logs')
      .setIcon(require('@/assets/images/calculators/info.svg')),
  ]

  openEditDialog() {
  }

  openResetPasswordDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 600,
      component: ChangePasswordDialog,
    })
  }

  get clients(): string {
    return this.user.clients.map((client: any) => client.name).join(', ')
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
