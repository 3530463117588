














import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldType } from '@/shared/configs/field-type.config'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Form from '@/components/form/Form.vue'
import { SnackBarType } from '@/shared/helpers/snackbar.helper'

@Component({
  components: { PageHeader, Form }
})
export default class ChangePasswordDialog extends Vue {
  @Prop() meta!: any

  form: FormBase = new FormBase()
    .setEndpoint('password/change')
    .setFields([
      new Field()
        .setType(FieldType.password)
        .setKey('old_password')
        .setTitle('Old password'),
      new Field()
        .setType(FieldType.password)
        .setKey('password')
        .setTitle('New password'),
      new Field()
        .setType(FieldType.password)
        .setKey('password_confirmation')
        .setTitle('Confirm new password'),
    ])
    .setSubmit(false)

  async submit() {
    const errors = await this.formRef.submit()
    if (errors) return

    this.closeDialog()
    this.$store.dispatch(GlobalActions.showSnackbar, {
      type: SnackBarType.success,
      message: 'Password was successfully changed',
    })

    this.meta.onSuccess && this.meta.onSuccess()
  }

  closeDialog() {
    this.$store.dispatch(GlobalActions.closeDialog)
  }

  get formRef(): any {
    return this.$refs.form
  }
}
