export default class Tab {
  title!: string
  name!: string
  icon!: any
  meta!: any

  setTitle(title: string): this {
    this.title = title

    return this
  }

  setName(name: string): this {
    this.name = name

    return this
  }

  setIcon(icon: any): this {
    this.icon = icon

    return this
  }

  setMeta(meta: any): this {
    this.meta = meta

    return this
  }
}
